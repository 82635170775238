.c {
  display: block;
}
.cAspnetSqlContainer {
  top: 720px;
  left: 354px;
  width: 164px;
  height: 135px;
}
.cAspnetSqlContainer,
.heyImBrendan,
.reactAngularAzureContainer {
  margin: 0;
  position: absolute;
  display: inline-block;
}
.reactAngularAzureContainer {
  top: 720px;
  left: 546px;
  width: 170px;
  height: 133px;
}
.heyImBrendan {
  top: 136px;
  left: 104px;
  font-size: 64px;
  font-weight: 700;
  font-family: inherit;
  width: 740px;
  height: 97px;
}
.imA {
  font-family: var(--font-roboto);
}
.imAFullStackContainer {
  margin: 0;
  position: absolute;
  top: 299px;
  left: 246px;
  display: inline-block;
  width: 941px;
  height: 316px;
}
.heresSomeOfMyRecentWork {
  margin: 0;
}
.heresSomeOfContainer {
  position: absolute;
  top: 922px;
  left: 246px;
  display: inline-block;
  width: 910px;
  height: 78px;
}
.createdANewVersionOfCusto {
  margin-bottom: 0;
}
.maintainedAndAddedFeatures {
  margin: 0;
  padding-left: var(--padding-24xl);
}
.healthProfessionalAssociatioContainer {
  margin: 0;
  position: absolute;
  top: 1045px;
  left: 354px;
  font-size: inherit;
  display: inline-block;
  width: 1175px;
  height: 801px;
}
.juneSeptember,
.october2020 {
  margin: 0;
  position: absolute;
  left: 1187px;
  display: inline-block;
  width: 342px;
  height: 70px;
}
.october2020 {
  top: 1045px;
}
.juneSeptember {
  top: 1456px;
}
.iTendToContainer,
.iveBeenCodingContainer {
  margin: 0;
  position: absolute;
}
.iveBeenCodingContainer {
  top: 2024px;
  left: 604px;
  display: none;
  width: 1305px;
  height: 134px;
}
.iTendToContainer {
  top: 630px;
  left: 246px;
  display: inline-block;
  width: 685px;
  height: 72px;
}
.desktop1 {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 2476px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
