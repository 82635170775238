@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-13xl: 32px;

  /* Colors */
  --color-black: #000;

  /* Paddings */
  --padding-24xl: 43px;
}
